import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngUnityGamePage = loadable(() => import("../components/UnityGamePage/english.js"));
const ArabicUnityGamePage = loadable(() => import("../components/UnityGamePage/arabic.js"));
const ChineseUnityGamePage = loadable(() => import("../components/UnityGamePage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@type": "product",
    "image": "https://softtik.com/images/nft-collection/about-unity-game.webp",
    "name": "World's Leading Unity Game Development Company | Softtik",
    "description": "Get into action by sharing your gaming ideas with the world's leading unity game development company that can turn your ideas into industry-leading projects. ",
    "brand": "Softtik Technologies",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "139"
    }

}

export class UnityGamePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/unity-game-development-services/"
                        title="World's Leading Unity Game Development Company | Softtik"
                        thumbnail="https://softtik.com/images/nft-collection/about-unity-game.webp"
                        description="Get into action by sharing your gaming ideas with the world's leading unity game development company that can turn your ideas into industry-leading projects. "
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page metaverse-token-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicUnityGamePage />
                                    : lang == "China"
                                        ? <ChineseUnityGamePage />
                                        : <EngUnityGamePage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default UnityGamePage;